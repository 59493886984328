<template>
    <!-- <div>
        <div class="cc-loader" :class="{ hidden : !is_loading }">
            <img src="/images/loader.svg" />
            <span>Carregando Produtos</span>
        </div>
        <table class="typing-order-header" :class="{ hidden : is_loading }">
            <thead>
                <tr>
                    <th>Produto/Marca</th>
                    <th class="orders">Req</th>
                    <th class="center">Qtd. Emb</th>
                    <th class="center">Pedido</th>
                    <th class="center">Und. Compra</th>
                    <th class="center">Estoque</th>
                    <th class="center">Digitação</th>
                    <th class="center">Ult. Compra</th>
                    <th class="right-align">Total</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(product, idx) in visible_products()" v-bind="'prod-parent-wrapper-' + product.idProduto">
                <tr v-bind:key="'prod-parent-' + product.idProduto" class="first">
                    <td class="product-description">


                        <i class="fas fa-angle-right" v-if="!product.expanded" @click="toggle_collapse(product)"></i>
                        <i class="fas fa-angle-down" v-if="product.expanded" @click="toggle_collapse(product)"></i>

                        <span class="name">{{ product.produtoDescricao }}</span>
                        <br />
                        <span class="provider">{{ product.pro_observacao }}</span>
                        <template v-if="product.observacao">
                            <span class="obs-desc">
                                <span class="observation">obs:</span>
                                {{ product.observacao }}
                            </span>
                        </template>
                    </td>
                    <td class="orders">{{ product.quantidade }}</td>
                    <td class="orders">
                        {{ (product.flag_generico == 1 || product.pro_principal) ? product.quantidadeDigitacao : product.embalagem.split(" ")[1] }}
                    </td>
                    <td class="unity">
                        <div class="unity-wrapper">
                            <input
                            min="0"
                            type="number"
                            :data-idx="idx"
                            ref="product-amount"
                            :class="{ disabled : !!subrequest.id_pedido, lower: product.firstValue > product.quantidade_sugestao, higher: product.firstValue < product.quantidade_sugestao }"
                            :readonly="subrequest && !!subrequest.id_pedido"
                            v-model="product.new_amount"
                            v-on:keydown="process_event($event, product)"
                            v-on:blur="process_event($event, product)"
                            :key="'qtd-' + idx"
                            />
                            <button class="save" :disabled="!!subrequest.id_pedido" v-on:click="!product.is_loading && update_quantity(product)">
                                <img :class="{ hidden: !product.is_loading }" src="/images/loader.svg" />
                                <i v-if="!product.is_loading && product.quantidade > product.new_amount" class="fa fa-arrow-down" ></i>
                                <i v-if="!product.is_loading && product.quantidade < product.new_amount" class="fa fa-arrow-up"></i>
                                <i v-if="!product.is_loading && product.quantidade == product.new_amount" class="fa fa-check" ></i>
                            </button>
                        </div>
                    </td>
                    <td class="unity left-align">
                        {{ product.pro_unidade_compra ? product.pro_unidade_compra : product.embalagem.split(" ")[0] }}
                        <i class="far fa-edit" @click="edit_wrap(product)" v-if="product.flag_generico == 0 && !product.pro_principal && !subrequest.id_pedido"/>
                    </td>

                    <td class="storage">{{ product.estoque ? product.estoque : '' }}</td>
                    <td class="typing-price" :class="{ higher: product.menorPreco > product.ultimaCompra, lower: product.menorPreco < product.ultimaCompra }">
                        <span>{{ product.menorPreco | currency }}</span>
                        <i v-show="subrequest && !subrequest.id_pedido" class="fa fa-handshake-o" title="Negociar" @click="open_negotiation_modal(product)"></i>
                    </td>
                    <td>
                        <div class="last-purchase-actions">
                            <i  v-show="product.ultimaCompra"
                                @click="show_orders(product)"
                                title="Histórico de Pedidos"
                                class="fas fa-clipboard-list"></i>
                            {{ product.ultimaCompra | currency }}
                        </div>
                    </td>
                    <td class="unity right-align">{{ product.menorPreco*product.quantidade_sugestao*( product.flag_generico == 1 || (product.pro_principal == null && product.cli_tipoPedido == 1) ? 1 : parseInt(product.embalagem.split(" ")[1])) | currency }}</td>
                </tr>

                <tr class="typings_loader" :class="{ hidden: !product.is_loading_typings }" v-bind:key="'typings-loader-' + product.idProduto">
                    <td colspan="10">
                        <img src="/images/loader.svg" />
                    </td>
                </tr>
                <tr v-bind:key="'prod-typings-' + product.idProduto" class="last" v-if="product.expanded && !product.is_loading_typings">
                    <td colspan="10">
                    <table class="product-providers">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Fornecedor</th>
                            <th>Marca</th>
                            <th>QTD. EMB</th>
                            <th>&nbsp; &nbsp; &nbsp;QTD</th>
                            <th>VL. UNITARIO</th>
                            <th>TOTAL ITEM</th>
                            <th>FAT. MIN</th>
                            <th>TOTAL FORNECEDOR</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr

                            v-for="(typing, idt) in product.digitacoes"
                            v-bind:key="typing.idFornecedor+'-prod-typing' + typing.pro_id"
                        >
                            <td>
                            <div class="checkbox-container">
                                <input
                                type="checkbox"
                                :checked="typing.vencedor_quantidade == 1"
                                v-on:click="toggle_winner(typing, product, typing.idVendedor, typing.pro_id, !(typing.vencedor_quantidade == 1))"
                                :disabled="!!subrequest.id_pedido || typing.qtdPedido > 0"
                                />
                                <span class="checkmark"></span>
                            </div>
                            </td>
                            <td>
                            <div style="position:relative;display:inline;">
                                <i v-if="typing.loading" class="fas fa-circle-notch fa-spin typing-loader"></i>
                            </div>
                            <span>{{ typing.nomeFornecedor }}</span>
                            <span
                                class="obs"
                                v-if="typing.observacao"
                            >
                                <span class="label">OBS:</span>
                                {{ typing.observacao }}
                            </span>
                            </td>
                            <td>{{ typing.pro_observacao }}</td>
                            <td>{{ typing.embalagem }}</td>
                            <td>
                              <div class="quantity-holder">
                                <input
                                    type="text"
                                    :ref="'dig-' + (typing.vencedor_quantidade != 1 ? 'dis-' : 'en-') + product.idProduto"
                                    :data-idt="idt"
                                    :class="{ disabled: typing.vencedor_quantidade != 1}"
                                    v-on:keyup="process_event_typing($event, typing, product)"
                                    v-on:blur="process_event_typing($event, typing, product)"
                                    :readonly="typing.vencedor_quantidade != 1"
                                    :disabled="!!subrequest.id_pedido"
                                    :value="typing.vencedor_quantidade == 1 ? typing.quantidade : 0"
                                />
                              </div>
                            </td>
                            <td>{{ typing.valor | currency }}</td>
                            <td>{{ typing.vencedor_quantidade == 1 ? typing.valor*typing.quantidade : 0 | currency }}</td>
                            <td>{{ typing.faturamento_minimo | currency }}</td>
                            <td>{{ typing.valorTotal | currency }}</td>
                        </tr>
                        </tbody>
                    </table>
                    </td>
                </tr>
                </template>
            </tbody>
        </table>
        <NegotiationModal
            @close="reset_negotitation"
            :typing="current_typing  || {}"
            :product="current_product || {}"
            v-if="current_typing"
            :quotation_id="$route.params.id" />
        <ProductWrapModal
            v-if="open_edit_wrap && current_product"
            :product="current_product"
            @close="close_wrap"
        />
        <ProductOrdersModal
            :product_id="current_product.pro_principal ? current_product.pro_principal : current_product.idProduto"
            :product_name="current_product.produtoDescricao"
            v-if="is_product_orders_modal_open"
            @close="is_product_orders_modal_open = false" />
    </div> -->
    <div>
        <div class="makeorder-page-table-header">
            <div class="makeorder-page-table-colapse-width"></div>
            <div class="makeorder-page-table-name-width">Produto/Marca</div>
            <div class="makeorder-page-table-req-width">Req.</div>
            <div class="makeorder-page-table-quantity-width">Qtd Emb.</div>
            <div class="makeorder-page-table-orders-width">Pedido</div>
            <div class="makeorder-page-table-unity-width">Und Compra</div>
            <div class="makeorder-page-table-stock-width">Estoque</div>
            <div class="makeorder-page-table-digi-width">Digitação</div>
            <div class="makeorder-page-table-uniprice-width">Unt. Compra</div>
            <div class="makeorder-page-table-totalprice-width">Total</div>
        </div>
        <div v-for="(product, idx) in visible_products()" :key="idx" >
            <div class="makeorder-page-table-row">
                <div class="makeorder-page-table-colapse-width makeorder-page-table-mobile-row">
                    <div class="makeorder-page-table-mobile-header"></div>
                    <span v-if="!product.expanded" @click="toggle_collapse(product)" style="font-size: 1.3em;" class="material-icons-outlined makeorder-page-table-colapse-icon">arrow_forward_ios</span>
                    <span v-else @click="toggle_collapse(product)" class="material-icons-outlined makeorder-page-table-colapse-icon">expand_more</span>
                </div>
                <div class="makeorder-page-table-name-width makeorder-page-table-name-text makeorder-page-table-mobile-row">
                    <div class="makeorder-page-table-mobile-header">Produto/Marca</div>
                    <div>
                        <div>{{ product.produtoDescricao }}</div>
                        <div class="makeorder-page-table-name-obs-container">
                            <div class="makeorder-page-table-name-obs">{{ product.pro_observacao }}</div>
                            <div v-if="product.observacao" style="display: flex">
                                <div style="color: var(--primary-color); background-color: lightyellow; padding: 1px 3px; border: 1px solid #FF7110; border-radius: 5px;">Obs:</div>
                                <div style="margin: 0 1vw;" class="makeorder-page-table-name-text">{{ product.observacao }}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="product.pro_ean > 500000" v-on:click="showProductInfo = product.pro_ean; buyPrice=product.menorPreco" class="makeorder-page-table-infoprice">
                        Codigo: 00000000
                        <span class="material-icons-outlined makeorder-page-table-infoprice-icon">local_offer</span>
                    </div>
                </div>
                <div class="makeorder-page-table-req-width makeorder-page-table-mobile-row">
                    <div class="makeorder-page-table-mobile-header">Req.</div>
                    {{ product.quantidade }}
                </div>
                <div class="makeorder-page-table-quantity-width makeorder-page-table-mobile-row">
                    <div class="makeorder-page-table-mobile-header">Qtd Emb.</div>
                    {{ (product.flag_generico == 1 || product.pro_principal) ? product.quantidadeDigitacao : product.embalagem.split(" ")[1] }}
                </div>
                <div class="makeorder-page-table-orders-width makeorder-page-table-mobile-row">
                    <div class="makeorder-page-table-mobile-header">Pedido</div>
                    <!-- <div :class="getInputStyle(product)" class="makeorder-page-table-input-container">
                        <input type="number" min="0" :id="'product-amount'+ idx" class="makeorder-page-table-input" v-on:keydown="process_event2($event, product, idx)" v-on:blur="process_event($event, product, idx)" v-model="product.new_amount">
                        <span v-if="product.new_amount > product.quantidade" style="color: green; font-size: 1.3em" class="material-icons-outlined">arrow_upward</span>
                        <span v-else-if="product.new_amount < product.quantidade" style="color: red; font-size: 1.3em" class="material-icons-outlined">arrow_downward</span>
                    </div> -->
                    <td class="unity">
                        <div class="unity-wrapper">
                            <input
                            min="0"
                            type="number"
                            :data-idx="idx"
                            ref="product-amount"
                            :class="{ disabled : !!subrequest.id_pedido, lower: product.firstValue > product.quantidade_sugestao, higher: product.firstValue < product.quantidade_sugestao }"
                            :readonly="subrequest && !!subrequest.id_pedido"
                            v-model="product.new_amount"
                            v-on:keydown="process_event($event, product)"
                            v-on:blur="process_event($event, product)"
                            :key="'qtd-' + idx"
                            />
                            <button class="save" :disabled="!!subrequest.id_pedido" v-on:click="!product.is_loading && update_quantity(product)">
                                <img :class="{ hidden: !product.is_loading }" src="/images/loader.svg" />
                                <i v-if="!product.is_loading && product.quantidade > product.new_amount" class="fa fa-arrow-down" ></i>
                                <i v-if="!product.is_loading && product.quantidade < product.new_amount" class="fa fa-arrow-up"></i>
                                <i v-if="!product.is_loading && product.quantidade == product.new_amount" class="fa fa-check" ></i>
                            </button>
                        </div>
                    </td>
                </div>

                <div class="makeorder-page-table-unity-width makeorder-page-table-mobile-row">
                    <div class="makeorder-page-table-mobile-header">Und Compra</div>
                    <div>
                        {{ product.pro_unidade_compra ? product.pro_unidade_compra : product.embalagem.split(" ")[0] }}
                        <i class="far fa-edit" style="cursor: pointer;" @click="edit_wrap(product)" v-if="product.flag_generico == 0 && !product.pro_principal && !subrequest.id_pedido"/>
                    </div>
                    
                </div>
                <div class="makeorder-page-table-stock-width makeorder-page-table-mobile-row">
                    <div class="makeorder-page-table-mobile-header">Estoque</div>
                    {{ product.estoque ? product.estoque : '-' }}
                </div>
                <div class="makeorder-page-table-digi-width makeorder-page-table-digi-text makeorder-page-table-mobile-row">
                    <div class="makeorder-page-table-mobile-header">Digitação</div>
                    <div style="display: flex; align-items: center;">
                        <i v-show="subrequest && !subrequest.id_pedido" class="fa fa-handshake-o makeorder-page-table-digi-icon" title="Negociar" @click="open_negotiation_modal(product)"></i>
                        <div :style="getDigiTextColor(product)">{{ product.menorPreco | currency }}</div>
                    </div>
                    
                </div>
                <div class="makeorder-page-table-uniprice-width makeorder-page-table-mobile-row">
                    <div class="makeorder-page-table-mobile-header">Unt. Compra</div>
                    {{ product.ultimaCompra | currency }}
                </div>
                <div class="makeorder-page-table-totalprice-width makeorder-page-table-mobile-row">
                    <div class="makeorder-page-table-mobile-header">Total</div>
                    {{ product.menorPreco*product.quantidade_sugestao*( product.flag_generico == 1 || (product.pro_principal == null && product.cli_tipoPedido == 1) ? 1 : parseInt(product.embalagem.split(" ")[1])) | currency }}
                </div>
            </div>
            <div v-if="product.expanded" class="makeorder-page-table-expand">
                <cc-loader v-if="product.is_loading_typings"/>
                <div v-else>
                    <div class="makeorder-page-table-expand-title">
                        <div class="makeorder-page-table-expand-colum-name">Fornecedor</div>
                        <div class="makeorder-page-table-expand-colum">Marcas</div>
                        <div class="makeorder-page-table-expand-colum">QTD. EMB</div>
                        <div class="makeorder-page-table-expand-colum">QTD</div>
                        <div class="makeorder-page-table-expand-colum">Valor Unitário</div>
                        <div class="makeorder-page-table-expand-colum">Total Item</div>
                        <div class="makeorder-page-table-expand-colum">Fat. Min</div>
                        <div class="makeorder-page-table-expand-colum">Total Fornecedor</div>
                    </div>
                    <div class="makeorder-page-table-expand-row" v-for="(typing, idx2) in product.digitacoes" :class="{'page-table-line-special': idx2 % 2 != 0 }" :key="idx2">
                        <!-- <div>
                            <input
                            type="checkbox"
                            :checked="typing.vencedor_quantidade == 1"
                            v-on:click="toggle_winner(typing, product, typing.idVendedor, typing.pro_id, !(typing.vencedor_quantidade == 1))"
                            :disabled="!!subrequest.id_pedido || typing.qtdPedido > 0"
                            />
                            <span></span>
                        </div> -->
                        <div v-if="typing.loading" style="width: 4vw;">
                            <cc-loader style="height: 5vw !important; position: absolute; margin-top: -5px; margin-left: -5px;" :show_txt="false" />
                        </div>
                        <div v-else style="width: 4vw;">
                            <span :title="getCheckBoxTitle(!!subrequest.id_pedido || typing.qtdPedido > 0)" :style="getCheckBoxStyle(!!subrequest.id_pedido || typing.qtdPedido > 0)" v-if="typing.vencedor_quantidade == 1" v-on:click="toggle_winner(typing, product, typing.idVendedor, typing.pro_id, !(typing.vencedor_quantidade == 1))" class="material-icons-outlined makeorder-page-table-expand-check">check_box</span>
                            <span v-else :title="getCheckBoxTitle(!!subrequest.id_pedido || typing.qtdPedido > 0)" :style="getCheckBoxStyle(!!subrequest.id_pedido || typing.qtdPedido > 0)" v-on:click="toggle_winner(typing, product, typing.idVendedor, typing.pro_id, !(typing.vencedor_quantidade == 1))" class="material-icons-outlined makeorder-page-table-expand-check">check_box_outline_blank</span>
                        </div>


                        <div class="makeorder-page-table-expand-colum-name makeorder-page-table-mobile-row">
                            <div class="makeorder-page-table-mobile-header">Fornecedor</div>
                            {{ typing.nomeFornecedor }}
                        </div>
                        <div class="makeorder-page-table-expand-colum makeorder-page-table-mobile-row">
                            <div class="makeorder-page-table-mobile-header">Marcas</div>
                            {{ typing.pro_observacao }}
                        </div>
                        <div class="makeorder-page-table-expand-colum makeorder-page-table-mobile-row">
                            <div class="makeorder-page-table-mobile-header">QTD. EMB</div>
                            {{ typing.embalagem }}
                        </div>
                        <div class="makeorder-page-table-expand-colum makeorder-page-table-mobile-row">
                            <div class="makeorder-page-table-mobile-header">QTD</div>
                            <input
                                type="text"
                                :ref="'dig-' + (typing.vencedor_quantidade != 1 ? 'dis-' : 'en-') + product.idProduto"
                                :data-idt="idx2"
                                class="makeorder-page-table-orders-input"
                                :class="{'makeorder-page-table-orders-input-disabled' : typing.vencedor_quantidade != 1 || typing.qtdPedido > 0, 'input-altered': typing.is_altered}"
                                v-on:keyup="process_event_typing($event, typing, product)"
                                v-on:blur="process_event_typing($event, typing, product)"
                                :readonly="!!subrequest.id_pedido || typing.qtdPedido > 0"
                                :disabled="!!subrequest.id_pedido || typing.qtdPedido > 0"
                                :value="typing.vencedor_quantidade == 1 ? typing.quantidade : 0"
                            />
                        </div>
                        <div class="makeorder-page-table-expand-colum makeorder-page-table-mobile-row">
                            <div class="makeorder-page-table-mobile-header">Valor Unitário</div>
                            {{ typing.valor | currency }}
                        </div>
                        <div class="makeorder-page-table-expand-colum makeorder-page-table-mobile-row">
                            <div class="makeorder-page-table-mobile-header">Total Item</div>
                            {{ typing.vencedor_quantidade == 1 ? typing.valor*typing.quantidade : 0 | currency }}
                        </div>
                        <div class="makeorder-page-table-expand-colum makeorder-page-table-mobile-row">
                            <div class="makeorder-page-table-mobile-header">Fat. Min</div>
                            {{ typing.faturamento_minimo | currency }}
                        </div>
                        <div class="makeorder-page-table-expand-colum makeorder-page-table-mobile-row">
                            <div class="makeorder-page-table-mobile-header">Total Fornecedor</div>
                            {{ typing.valorTotal | currency }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <NegotiationModal
            @close="reset_negotitation"
            :typing="current_typing  || {}"
            :product="current_product || {}"
            v-if="current_typing"
            :quotation_id="$route.params.id" />
        <ProductWrapModal
            v-if="open_edit_wrap && current_product"
            :product="current_product"
            @close="close_wrap"
        />
        <ProductOrdersModal
            :product_id="current_product.pro_principal ? current_product.pro_principal : current_product.idProduto"
            :product_name="current_product.produtoDescricao"
            v-if="is_product_orders_modal_open"
            @close="is_product_orders_modal_open = false" />
        <InfoPriceProductModal v-if="showProductInfo" :productGtin="showProductInfo" :apiService="infoPriceService" :buyPrice="buyPrice" :close="() => showProductInfo = undefined"/>
    </div>
</template>
<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import * as CONSTANTS from "@/constants/constants";
import QuotationService from "@/services/QuotationService";
import ProductService from "@/services/ProductService";
import OrderService from "@/services/OrderService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import popoverLastBuy from "@/components/popovers/last-buy.popover";
import ProductOrdersModal from '@/modals/products/orders';
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import InfoPriceProductModal from "@/components/shared/InfoPriceProductModal.vue";
import InfoPriceService from "../../services/InfoPrice";
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'

export default {
    props: {
        subrequest: Object,
        filterProducts: String
    },
    components: {
        ccPagination: Pagination,
        popoverLastBuy,
        NegotiationModal,
        ProductOrdersModal,
        ProductWrapModal,
        InfoPriceProductModal
    },
  data() {
    return {
      infoPriceService: new InfoPriceService(),
      is_loading: true,
      CONSTANTS: CONSTANTS,
      backRoute: 1,
      backOrdered: 0,
      backRouteName: "cliente-monitores-vencedores",
      sellers: [],
      openedPopovers: [],
      orderService: new OrderService(),
      quotationService: new QuotationService(),
      product_svc: new ProductService(),
      current_seller: null,
      current_typing: null,
      current_product: null,
      search_terms: null,
      selected_status_filter: "WINNERS_ONLY",
      quotationId: [],
      products: [],
      currentOrder: null,
      is_product_orders_modal_open: false,
      open_edit_wrap: false,
      showProductInfo: undefined,
      breadcrumbs: [
        {
          link: CONSTANTS.ROUTE_MONITORS,
          name: "Monitor de Cotações"
        },
        {
          link:
            CONSTANTS.ROUTE_MONITORS + "/vencedores/" + this.$route.params.id,
          name: "Cotação " + this.$route.params.id
        },
        {
          name: "Pré-Pedidos"
        },
        {
          name: "Realizar Pedido"
        }
      ],
      buyPrice: undefined
    };
  },
    methods: {
        getCheckBoxStyle (value) {if (value) return 'color: lightgray; cursor: default'},
        getCheckBoxTitle (value) {if (value) return 'Pedido ja realizado para esse fornecedor.'},
        getSpecialBackground (index) {
            if (index % 2 != 0) return 'page-table-line-special'
        },
        getInputStyle (product) {
            if (product.new_amount > product.quantidade) return 'makeorder-page-table-input-container-up'
            else if (product.new_amount < product.quantidade) return 'makeorder-page-table-input-container-down'
        },
        getDigiTextColor(product){
            if (product.menorPreco > product.ultimaCompra) return 'color: white; background-color: red; opacity: 0.5; padding: 0.2rem; border-radius: 6px;'
            if (product.menorPreco < product.ultimaCompra) return 'color: white; background-color: green; opacity: 0.5; padding: 0.2rem; border-radius: 6px;'
            return 'color: #605F5F;'
        },
        close_wrap(reload = false) {
            this.current_product = null;
            this.open_edit_wrap = false
            if(reload) {
                this.$emit("reload_provider");
            }
        },
        edit_wrap(product) {
            this.current_product = product
            this.open_edit_wrap = true
        },
        show_orders(product) {
            if(product.ultimaCompra) {
                this.current_product = product
                this.is_product_orders_modal_open = true
            }
        },
        open_negotiation_modal(product) {

            this.current_product = product;

            let resolve = (data) => {
                if(data) {
                    this.$set(product, "digitacoes", data.digitacoes);
                }
                this.current_typing = product.digitacoes.filter(offer => {
                    return offer.idFornecedor == this.subrequest.for_id &&
                        offer.idVendedor == this.subrequest.vendedor_id &&
                        offer.vencedor_quantidade == 1 &&
                        offer.pro_id == product.idProduto
                })[0]
            }

            if(!product.digitacoes) {
                this.load_offers(product).then(resolve)
            } else {
                resolve();
            }

        },
        reset_negotitation(with_reload = false, new_value) {
            if(with_reload) {
                this.current_product.menorPreco = new_value
            }
            this.current_typing = null;
            if(with_reload) {
                this.load_offers(this.current_product).then(data => {
                this.$set(this.current_product, "digitacoes", data.digitacoes);
                this.$emit("reload_provider");
                })
            }
        },
        print() {
            self.print();
        },
        openPopover(popover) {
            this.openedPopovers.push(popover);
            this.$refs[popover][0].style.display = "block";
        },
        closePopover(popover) {
            const index = this.openedPopovers.indexOf(popover);
            if (index > -1) {
                this.openedPopovers.splice(index, 1);
            }
            this.$refs[popover][0].style.display = "none";
        },
        process_event_typing2: function(e, typing, product, index1 , index2) {
            e.preventDefault()
            let new_amount = parseInt(e.target.value);
            typing.new_amount = new_amount;
            if (e.key == "ArrowDown"){
                e.preventDefault()
                let element = document.getElementById('typing-amount'+ (index1 + '-' + (index2 + 1)))
                if (!element) return
                if (element.className.includes('makeorder-page-table-orders-input-disabled')) this.process_event_typing2(e, typing, product, index1 , index2 + 1)
                else {
                    element.focus()
                    element.select()
                }
            } else if (e.key == "ArrowUp"){
                e.preventDefault()
                let element = document.getElementById('typing-amount'+ (index1 + '-' + (index2 - 1)))
                if (!element) return
                if (element.className.includes('makeorder-page-table-orders-input-disabled')) this.process_event_typing2(e, typing, product, index1 , index2 - 1)
                else {
                    element.focus()
                    element.select()
                }
            }
            this.update_quantity(typing, () => {
                    this.$set(typing, "quantidade", typing.new_amount);
                    if (product.idProduto == typing.pro_id && typing.nomeFornecedor == this.subrequest.for_nome) {
                        this.$set(product, "new_amount", typing.new_amount);
                        this.$set(product, "quantidade_sugestao", typing.new_amount);
                    }
                    this.$emit("reload_provider");
                    this.load_offers(product).then(data => {
                        this.$set(product, "digitacoes", data.digitacoes);
                    });
                });
        },
        process_event_typing: function(e, typing, product) {
            e.preventDefault();
            let key = getKey(e);
            let new_amount = parseInt(e.target.value);
            let idt = parseInt(e.target.dataset.idt);
            let inputs = this.$refs["dig-en-" + product.idProduto];
            typing.new_amount = new_amount;
            if (typing.new_amount != typing.quantidade) typing.is_altered = true
            typing.idProduto = typing.pro_id;
            function getNext() {
                let result = null;
                for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].dataset.idt == idt) {
                    if (i == inputs.length - 1) {
                    result = inputs[0];
                    } else {
                    result = inputs[i + 1];
                    }
                    break;
                }
                }
                return result;
            }
            function getPrev() {
                let result = null;
                for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].dataset.idt == idt) {
                    if (i == 0) {
                    result = inputs[inputs.length - 1];
                    } else {
                    result = inputs[i - 1];
                    }
                    break;
                }
                }
                return result;
            }
            function getCurrent() {
                let result = null;
                for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].dataset.idt == idt) {
                    result = inputs[i];
                    break;
                }
                }
                return result;
            }
            function getKey(e) {
                if (e.key) return e.key;
                let keyFromCode = String.fromCharCode(e.keyCode);
                if (keyFromCode) return keyFromCode;
                // add here the tricky keys that you use in your app
                if (e.keyCode === 13) return "Enter";
                if (e.keyCode === 16) return "Shift";
                if (e.keyCode === 8) return "Tab";
                // etc
            }
            if (key == "Enter" || key == "ArrowDown" || key == "ArrowUp" || e.type == "blur") {
                this.update_quantity(typing, () => {
                    this.$set(typing, "quantidade", typing.new_amount);
                    if (product.idProduto == typing.pro_id && typing.nomeFornecedor == this.subrequest.for_nome) {
                        this.$set(product, "new_amount", typing.new_amount);
                        this.$set(product, "quantidade_sugestao", typing.new_amount);
                    }
                    this.$emit("reload_provider");
                    // this.load_offers(product).then(data => {
                    //     this.$set(product, "digitacoes", data.digitacoes);
                    // });

                    if(e.type !== "blur")
                    Vue.nextTick(() => {
                        if (key == "ArrowDown") {
                            getNext().focus();
                            getNext().select()
                        } else if (key == "ArrowUp") {
                            getPrev().focus();
                            getPrev().select();
                        } else {
                            getCurrent().focus();
                            getCurrent().select();
                        }
                    });
                });
            }
        },
        process_event2: function(e, product, index){
            if (e.key == "ArrowDown"){
                e.preventDefault()
                let element = document.getElementById('product-amount'+ (index + 1))
                if (!element) return
                else {
                    element.focus()
                    element.select()
                }
            } else if (e.key == "ArrowUp"){
                e.preventDefault()
                let element = document.getElementById('product-amount'+ (index - 1))
                if (!element) return
                else {
                    element.focus()
                    element.select()
                }
            }
        },
        process_event: function(e, product, index) {
            // if (product.firstValue != product.new_amount) {
            //     this.update_quantity(product, () => {
            //         product.firstValue = product.new_amount
            //         product.is_loading = false;
            //         this.$emit("reload_provider");
            //         this.load_offers(product).then(data => {
            //             this.$set(product, "digitacoes", data.digitacoes);
            //         });
            //     });
            // }

            let key = getKey(e);
            let idx = parseInt(e.target.dataset.idx);
            let inputs = this.$refs["product-amount"];
            let next_idx = idx + 1 == inputs.length ? 0 : idx + 1;
            let prev_idx = idx == 0 ? inputs.length - 1 : idx - 1;
            function getKey(e) {
                if (e.key) return e.key;
                let keyFromCode = String.fromCharCode(e.keyCode);
                if (keyFromCode) return keyFromCode;
                // add here the tricky keys that you use in your app
                if (e.keyCode === 13) return "Enter";
                if (e.keyCode === 16) return "Shift";
                if (e.keyCode === 8) return "Tab";
                // etc
            }
            this.$set(product, "is_loading", true);
            if (key == "ArrowUp") {
                e.preventDefault();
                this.update_quantity(product, () => {
                    inputs[prev_idx].focus();
                    inputs[prev_idx].select();
                    product.is_loading = false;
                    this.$emit("reload_provider");
                    this.load_offers(product).then(data => {
                        this.$set(product, "digitacoes", data.digitacoes);
                    });
                });
            } else if (key == "ArrowDown") {
                e.preventDefault();
                this.update_quantity(product, () => {
                    inputs[next_idx].focus();
                    inputs[next_idx].select();
                    product.is_loading = false;
                    this.$emit("reload_provider");
                    this.load_offers(product).then(data => {
                        this.$set(product, "digitacoes", data.digitacoes);
                    });
                });
            } else if (key == "Enter") {
                e.preventDefault();
                this.update_quantity(product, () => {
                    inputs[idx].focus();
                    inputs[idx].select();
                    product.is_loading = false;
                    this.$emit("reload_provider");
                    this.load_offers(product).then(data => {
                        this.$set(product, "digitacoes", data.digitacoes);
                    });
                });
            } else if(e.type == "blur") {
                this.update_quantity(product, () => {
                    product.is_loading = false;
                    this.$emit("reload_provider");
                    this.load_offers(product).then(data => {
                        this.$set(product, "digitacoes", data.digitacoes);
                    });
                });
            } else {
                // inputs[idx].focus();
                // inputs[idx].select();
                product.is_loading = false;
            }
        },
        toggle_winner(typing, product, sellerId = this.$route.params.sellerid, prod_id, will_be_winner = !this.is_winner(product)) {

            if (!!this.subrequest.id_pedido || typing.qtdPedido > 0) return

            if (product.quantidade > 0 || typing.vencedor_quantidade > 0) {
                this.$set(typing, 'loading', true);
                this.quotationService
                .toggleQuotationWinner(this.$route.params.id, this.$route.params.sid, prod_id, sellerId, will_be_winner)
                .then(response => response.data)
                .then(() => {
                    this.quotationService.getDigitacaesProdutoComparativo(
                        this.$route.params.id,
                        this.$route.params.sid,
                        product.idProduto
                    ).then(response => response.data).then(data => {
                        this.$set(product, "digitacoes", data.digitacoes)
                        this.$set(typing, 'loading', false)
                        if (product.idProduto == typing.pro_id && typing.nomeFornecedor == this.subrequest.for_nome) {
                            if(!will_be_winner) {
                                this.$set(product, 'new_amount', 0)
                                this.$set(product, 'quantidade_sugestao', 0)
                            } else {
                                this.$set(product, 'quantidade_sugestao', product.quantidade)
                                this.$set(product, 'new_amount', product.quantidade)
                            }
                        }
                        this.$emit("reload_provider");
                    })
                })
            } else {
                this.quotationService
                        .toggleQuotationWinner(this.$route.params.id, this.$route.params.sid, prod_id, sellerId, will_be_winner)
                        .then(() => console.log("updating winner"))
                this.$set(typing, 'vencedor_quantidade', typing.vencedor_quantidade > 0 ? 0 : 1)
            };
        },
        order() {
            let data = {
                idcotacao: this.$route.params.id,
                idvendedor: this.$route.params.sellerid,
                loja: this.$route.params.sid
            }
            this.$swal.fire({
                title: "Confirma realização do pedido?",
                text: "Seu pedido será enviado ao fornecedor!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, realizar pedido!"
            }).then(result => {
                if (result.value) {
                    this.is_loading = true;
                    this.quotationService.order(data)
                    .then(response => response.data)
                    .then(data => {
                        this.is_loading = false;
                        this.$swal.fire(
                            "Realizado!",
                            "Seu pedido foi realizado com sucesso.",
                            "success"
                        );
                        this.update();
                    }, err => {
                        this.is_loading = false;
                        this.$swal.fire(
                            "Erro!",
                            "Ocorreu um erro ao realizar o pedido.",
                            "error"
                        );
                        }
                    );
                }
            });
        },
        update_quantity(product, callback) {
            if (!product.new_amount) product.new_amount = "0";
            let payload = {
                numerocotacao: this.$route.params.id,
                quantidade: product.new_amount,
                vendedor: product.idVendedor
                ? product.idVendedor
                : this.$route.params.sellerid,
                produto: product.idProduto,
                loja: this.$route.params.sid
            };
            this.$set(product, "new_amount", payload.quantidade);
            let productLoading = product.is_loading;
            this.$set(product, "is_loading", true);
            return this.quotationService
            .update_quantity(payload)
            .then(response => response.data)
            .then(data => {
                if(!productLoading){
                    product.is_loading = false;
                }
                this.$set(product, "quantidade_sugestao", payload.quantidade);
                callback();
                this.reload_subrequest();
            }, err => {
                product.is_loading = false;
            })
        },
        cancel_order() {
            let data = {
                numeroCotacao: this.$route.params.id,
                vendedor: this.$route.params.sellerid,
                loja: this.$route.params.sid
            };
            this.$swal
                .fire({
                title: "Confirma cancelamento do pedido?",
                text: "Seu pedido será cancelado!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, cancelar pedido!"
                })
                .then(result => {
                if (result.value) {
                    this.quotationService
                    .cancel_order(data)
                    .then(response => response.data)
                    .then(
                        data => {
                            this.$swal.fire(
                                "Sucesso!",
                                "Seu pedido foi cancelado com sucesso.",
                                "success"
                            );
                            this.update();
                        },
                        err => {
                        this.$swal.fire(
                            "Erro!",
                            "Ocorreu um erro ao cancelar o pedido.",
                            "error"
                        );
                        }
                    );
                }
            });
        },
        reload_subrequest() {
            // TODO
        },
        checkReturnRoute() {
            if(this.subrequest) {
                if(this.subrequest.id_pedido) {
                    this.backOrdered = 0;
                } else if(this.subrequest.id_pedido) {
                    this.backOrdered = 1;
                }
            }
        },
        has_observation(product) {
            return product.observacao;
        },
        is_winner(product) {
            return (product.vencedor_multiplo == 1 || product.vencedor == 1) && product.quantidade_sugestao > 0;
        },
        visible_products() {
            // return this.products.filter(p => p.visible == undefined || p.visible);
            const resp = []
            this.products.forEach(product => {
                if (this.filterProducts) {
                    if (product.produtoDescricao.toUpperCase().includes(this.filterProducts.toUpperCase()) && product.visible) resp.push(product)
                }
                else if (product.visible) resp.push(product)
            })
            return resp
        },
        search(evt) {
            this.products.forEach(p => {
                if (new RegExp(this.search_terms, "i").test(p.descricaoProduto) && this.is_winner(p)) {
                    this.$set(p, "visible", true);
                } else {
                    this.$set(p, "visible", false);
                }
            })
        },
        filter_by_status() {
            this.products.forEach(p => {
                if (this.is_winner(p)) {
                    this.$set(p, "visible", true);
                } else {
                    this.$set(p, "visible", false);
                }
            })
        },
        subrequest_total() {
            return this.subrequest.valorTotal;
        },
        toggle_collapse(product, force_reload = false) {
            this.$set(product, "expanded", !product.expanded || force_reload);
            if (!product.digitacoes || force_reload) {
                this.$set(product, "is_loading_typings", true);
                this.load_offers(product, force_reload).then(data => {
                this.$set(product, "is_loading_typings", false);
                this.$set(product, "digitacoes", data.digitacoes);

                });
            }
        },
        load_offers(product) {
            return this.quotationService.getDigitacaesProdutoComparativo(
                this.$route.params.id,
                this.$route.params.sid,
                product.idProduto
            ).then(response => response.data)
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return "" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getProducts() {
            var quotationService = new QuotationService();
            quotationService.getProdutosDigitacaoComparativo(
                this.$route.params.id,
                this.$route.params.sellerid,
                this.$route.params.sid
            ).then(response => response.data).then(data => {
                let productsArray = data.produtos;
                productsArray.forEach(p => {
                    p.new_amount = p.quantidade_sugestao;
                    p.firstValue = p.quantidade_sugestao;
                });
                this.products = productsArray;
                this.total_items = data.total;
                if (data.valorTotal)
                    data.valorTotal.forEach(d => (this.total_pre_orders += d.total));
                this.filter_by_status();
                this.is_loading = false;
            }).catch(error => ErrorHandlerService.handle(error, this.$store))
        },
        update() {
            this.is_loading = true;
            this.checkReturnRoute();
            this.getProducts();
        }
    },
    created() {
        this.update();
        if (this.$route.query.backRoute == 2) {
            this.backRoute = 2;
            this.backRouteName = this.$route.params.backRouteName;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "./typings.component";
.makeorder-page-table-infoprice{
    font-weight: 400;
    font-size: 1.1em;
    color: var(--primary-color);
    cursor: pointer;
}
.makeorder-page-table-infoprice-icon{font-size: 1em;}

.left-align {
  text-align: left !important;
}
.makeorder-page-table-header{
    display: flex;
    padding: 1vh 0.5vw;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}
.makeorder-page-table-colapse-width{width: 5%;}
.makeorder-page-table-name-width{flex: 1}
.makeorder-page-table-req-width{width: 7%;}
.makeorder-page-table-quantity-width{width: 7%;}
.makeorder-page-table-orders-width{width: 7%;}
.makeorder-page-table-unity-width{width: 12%;}
.makeorder-page-table-stock-width{width: 7%;}
.makeorder-page-table-digi-width{
    width: 10%;
    display: flex;
    align-items: center;
}
.makeorder-page-table-uniprice-width{
    width: 10%;
    display: flex;
    align-items: center;
}
.makeorder-page-table-totalprice-width{width: 7%;}
.makeorder-page-table-row{
    display: flex;
    padding: 1vh 0.5vw;
    font-weight: 300;
    font-size: 1.1em;
    color: #605F5F;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
}
.makeorder-page-table-name-text{
    color: #605F5F;
    margin-right: 1vw;
    font-weight: 600;
}
.makeorder-page-table-colapse-icon{
    color: var(--primary-color);
    font-size: 2em;
    cursor: pointer;
}
.makeorder-page-table-mobile-header{display: none;}
.makeorder-page-table-digi-text{font-weight: 600;}
.makeorder-page-table-digi-icon{
    color: var(--primary-color);
    font-size: 1.2rem;
    margin-right: 0.5vw;
    cursor: pointer;
}
.makeorder-page-table-uniprice-icon{
    color: #4267B2;
    font-size: 1.2rem;
    margin-right: 0.5vw;
    cursor: pointer;
}
.makeorder-page-table-expand{margin-left: 1.5vw;}
.makeorder-page-table-expand-title{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.1em;
    color: #A1A1A1;
    margin-bottom: 2vh;
}
.makeorder-page-table-name-obs-container{
    display: flex; 
    justify-content: space-between; 
    align-items: center;
}
.makeorder-page-table-expand-row{
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1.1em;
    color: #A1A1A1;
    padding: 1vh 1vw;
}
.makeorder-page-table-expand-colum{width: 10%;}
.makeorder-page-table-expand-colum-name{flex: 1}
.page-table-line-special{background: #F7F7F7;}
.makeorder-page-table-input{
    border: none;
    background-color: transparent;
}
.makeorder-page-table-input:focus{
    outline: none;
}
.makeorder-page-table-input-container{
    display: flex;
    background: rgba(255, 113, 16, 0.04);
    border: 1px solid #FF7110;
    border-radius: 8px;
    width: 85%;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.75vw;
}
.makeorder-page-table-input-container-up{border: 1px solid #30AA4C;}
.makeorder-page-table-input-container-down{border: 1px solid #EF443B;}
.makeorder-page-table-orders-input{
    text-align: left;
    outline: none;
    border: 1px solid #FF7110;
    border-radius: 8px;
    padding-left: 1vw;
}
.makeorder-page-table-orders-input:focus{
    background-color: lightsalmon;
}
.makeorder-page-table-orders-input-disabled{
    background-color: lightgray;
    border: 1px solid #505050;
    cursor: default;
}
.makeorder-page-table-orders-input-up{
    background-color: lightcoral;
    color: white;
}
.makeorder-page-table-orders-input-dow{
    background-color: lightgreen;
    color: white;
}
.makeorder-page-table-expand-check{
    color: var(--primary-color);
    cursor: pointer;
    margin-right: 1.5vw;
}
.makeorder-page-table-name-obs{
    color: var(--primary-color);
    font-size: 1em;
}
.page-table-line-special{
    background: #F7F7F7;
}
.input-altered{background-color: #13c7213d; border: 1px solid #0606064d !important;}
.unity {
box-sizing: unset;
.fa-edit {
    margin-left: 10px;
    color: $primary-color;
    cursor: pointer;
}
.unity-wrapper {
    display: flex;
    align-items: center;
    img {
        height: 18px;
    }
    input[type=number] {
        border-radius: 10px 0 0 10px;
        border-width: 0;
        width: 50px;
        border: 4px solid #0606064d;
        display: inline-block;
        &:focus {
            border: 3px solid $primary-color;;
            outline: none;
            box-shadow: 0px 0px 0px 3px $primary-color inset;
        }
        &.higher {
            background-color: #13c7213d;
        }
        &.lower {
            background-color: #13c7213d;
        }
    }
    button {
        .fa-arrow-up {
            color: #0033ef9e;
        }
        .fa-arrow-down {
            color: #fb7272;
        }
        &.save {
            float: right;
            color: green;
            height: 32px;
            padding: 4px;
            position: relative;
            border-left: none;
            width: 1.5rem;
            border-radius: 0px 10px 10px 0px !important;
            i {
                border-color: #0606064d;
                left: 0.4rem;
                position: absolute;
                font-size: 0.9rem;
                margin: 0;
            }
        }
    }
}
}
@media only screen and (max-width: 750px) {
    .makeorder-page-table-header{display: none;}
    .makeorder-page-table-row{
        flex-direction: column;
        gap: 1vh;
        font-size: 1.5em;
    }
    .makeorder-page-table-expand-row{
        flex-direction: column;
        gap: 1vh;
        font-size: 1.5em;
    }
    .makeorder-page-table-mobile-row{
        display: flex;
        flex: unset;
        width: 100%;
        justify-content: space-between;
        text-align: end;
        align-items: center;

    }
    .makeorder-page-table-mobile-header{
        font-weight: bold;
        font-size: 1.2em;
        display: flex;
    }
    .makeorder-page-table-name-text{margin-right: 0;}
    .makeorder-page-table-name-obs-container{justify-content: end;}
    .makeorder-page-table-expand-title{display: none;}
}
</style>
